import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import parse from 'html-react-parser';
import moment from 'moment';
import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import SEO from '../components/SEO';
import { postCreators } from '../utils';
const PortofolioPage = ({ data }) => {
  return (
    <>
      <SEO
        title={data.post.title.value}
        description={data.post.description.value}
      />
      <section className="portfolio-container pt-40 pb-8 px-2 sm:px-16 2xl:px-24  min-h-screen">
        <h3 className="text-3xl font-semibold mb-3">{data.post.title.value}</h3>
        <div className="meta-wrapper">
          <div className="info-post">
            <a href="">
              <FontAwesomeIcon icon={faCircleUser} />{' '}
              {postCreators[data.post.cockpitBy] || '-'}
            </a>
            <span>{moment(data.post.date.value).format('DD MMM YYYY')}</span>
          </div>
          <div className="info-shared">
            <TwitterShareButton
              url={`http://localhost:8001/portofolio/${data.post.slug.value}`}
            >
              <TwitterIcon
                size={20}
                round={true}
                bgStyle={{ fill: '#2C3E50' }}
              />
            </TwitterShareButton>
            <FacebookShareButton
              url={`http://localhost:8001/portofolio/${data.post.slug.value}`}
            >
              <FacebookIcon
                size={20}
                round={true}
                bgStyle={{ fill: '#2C3E50' }}
              />
            </FacebookShareButton>
            <LinkedinShareButton
              url={`http://localhost:8001/portofolio/${data.post.slug.value}`}
            >
              <LinkedinIcon
                size={20}
                round={true}
                bgStyle={{ fill: '#2C3E50' }}
              />
            </LinkedinShareButton>
            <WhatsappShareButton
              url={`http://localhost:8001/portofolio/${data.post.slug.value}`}
            >
              <WhatsappIcon
                size={20}
                round={true}
                bgStyle={{ fill: '#2C3E50' }}
              />
            </WhatsappShareButton>
          </div>
        </div>
        {data.post.main_image && (
          <div className="my-6 mx-auto">
            <Image fluid={data.post.main_image.value.childImageSharp.fluid} />
          </div>
        )}

        <div className="content-wrapper mx-auto py-2">
          {parse(data.post.content.value.transform.html)}
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  query ($slug: String!) {
    post: cockpitPortofolio(slug: { value: { eq: $slug } }) {
      slug {
        value
      }
      cockpitBy
      date {
        value
      }
      description {
        value
      }
      content {
        value {
          transform: childMarkdownRemark {
            html
          }
        }
      }
      main_image {
        value {
          childImageSharp {
            fluid(maxWidth: 1280, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      title {
        value
      }
    }
  }
`;

export default PortofolioPage;
